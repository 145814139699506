@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

hr {
  height: 3px;
  background-color: #1e595d;
  opacity: 1;
}

#root .btn-outline-dark {
  --bs-btn-bg: #1e595d;
  --bs-btn-color: #ffffff;
}

#filter,
#accordionFilter {
  --bs-body-bg: #1e595d;
  --bs-body-color: #ffffff;
}

.page-link, .nav-link, .navbar-brand {
  background-color: #1e595d;
  color: #ffffff;
}

.nav-link:hover, .nav-link:focus, #features .card {
  background-color: #128c7e;
}

.container-fluid, #footer {
  background-color: #1e595d !important;;
}

#filter .btn-outline-dark,
#accordionFilter .btn-outline-dark {
  color: #1e595d;
  border-color: #ffffff;
  background-color: #ffffff;
}


#image-container:hover {
  background-color: #ffffff !important;
}

#image-container img:hover {
  border: double;
}

#filter .btn-outline-dark:hover,
#accordionFilter .btn-outline-dark:hover,
.card:hover,
.dropdown-item:hover,
#features .card:hover,
.bg-dim {
  background-color: #4d9ea2;
}

.cover {
  object-fit: cover;
}

.image-vertical-scroller {
  overflow-y: auto;
  max-height: 460px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.margin-form {
  padding: 2rem;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.center-form {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.input-icon {
  position: absolute;
  height: 20px;
  right: 10px;
  top: 25%;
}

.input-wrapper {
  position: relative;
}

.error {
  color: red;
  font-size: 12px;
}

.mercadopago-button {
  background: #212529 !important;
  width: 100%;
}

.mercadopago-button:hover {
  background: #373b3e !important;
}

.carousel-control-next span,
.carousel-control-prev span {
  background-color: rgba(0, 0, 0, 0.5);
}

.webchat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.webchat-button:hover {
  background-color: #128c7e;
}
